<template>
  <div class="flex flex-col">
    <input
      ref="inputRef"
      v-bind="$attrs"
      v-model="inputValue"
      :name="name"
      :type="$props.type"
    >

    <span
      v-if="error"
      class="text-red-600 text-sm"
    >{{ error }}</span>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';

withDefaults(
  defineProps<{
    name: string;
    type: string;
    // modelValue: string | number | null | undefined;
    error?: string;
  }>(),
  {
    error: undefined,
    /* eslint-disable no-null/no-null */
    // modelValue: null,
  },
);

// defineEmits(['update:modelValue']);

const inputValue = defineModel();
const inputRef = ref<HTMLInputElement>();

const focusInput = () => {
  inputRef.value?.focus();
};

const blur = () => {
  inputRef.value?.blur();
};

defineExpose({
  focusInput,
  blur,
});
</script>

<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>

<style lang="css" scoped></style>
